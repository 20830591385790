export interface IDocumentHistorySearchCriteriaDto {
  RelatedTo: IDocumentRelatedToKeysDto[];
}
export interface IOnlineActivityDto extends IValidatableDto, IDomainChecklistDtoHost, IFileUploadDtoHost {
  OnlineComment: string;
  ActivitySubactivityId?: number;

  StartDate?: Date;
  EndDate?: Date;
  ExpectedDate?: Date;
  CanceledDate?: Date;

  ActivitySubactivity: IActivitySubactivityDto;
  Status: IActivityStatusDto;
}
export interface IActivitySubactivityDto {
  ActivitySubtype: IActivitySubtypeDto;
}
export interface IOnlineRelatedActivitySearchResultItemDto {
  Id: number;
  SubActivityTypeDescription: string;
  StatusDescription: string;
  StartDate?: Date;
  EndDate?: Date;
  ExpectedDate?: Date;
}
export interface IOnlineRelatedActivitySearchCriteriaDto extends IValidatableDto, IFunctionalItemSupport {
}
export interface IDomainKeywordItemProfessionTypeDto extends ILookupDto, IAuditCreate, IAuditModifications
{
    DomainKeywordItemId: number
    ProfessionTypeId: number
    ProfessionType: IProfessionTypeDto
}

export interface ILookupDomainKeywordItemDto extends ILookupDto, IAuditCreate, IAuditModifications {
  DomainKeywordGroupId: number
  Definition: string
  DomainKeywordGroup: IDomainKeywordGroupDto
  ProfessionTypes: IDomainKeywordItemProfessionTypeDto[]
}

export interface IDomainKeywordDto extends IAuditCreate, IAuditModifications {
  LookupDomainKeywordItemId?: number | null;
  LookupDomainKeywordItem: ILookupDomainKeywordItemDto;
}

export interface IEntityRelatedToItemSupport extends IFunctionalItemSupport, IBaseDto {
  RelatedToDescription: string;
  FunctionType: IFunctionTypeDto;

}
export interface IParentFunctionalItemSupport {
  ParentFunctionTypeId?: number;
  ParentFunctionNumber: string;
  ParentProfessionTypeCode: string;

}
export interface IChildDomainItemSupport {
  ChildDomainId?: number;
  ChildDomainKeyId?: number;
  TemporaryChildDomainKeyId?: any;

}
export interface IConcurrencySupport {
  RowVersion: number[];

}
export interface IDomainItemSupport {
  DomainId: number;
  DomainKeyId: number;
  TemporaryDomainKeyId: any;

}
export interface IFunctionalItemSupport {
  FunctionTypeId?: number;
  FunctionNumber: string;
  ProfessionTypeCode: string;

}
export interface IParentDomainItemSupport {
  ParentDomainId?: number;
  ParentDomainKeyId?: number;
  TemporaryParentDomainKeyId?: any;

}
export interface IActivityDocumentSubmissionDto extends IFileUploadDtoHost {
  ActivityInfo: IOnlineActivityInfoDto;

}
export interface IActivityInfoDto extends IDto {
  Id: number;
  EntityId?: number;
  Staff: string;
  StartDate?: Date;
  EndDate?: Date;
  ExpectedDate?: Date;
  RelatedToDescription: string;
  StatusModifiedDate?: Date;
  StatusModifiedBy: string;
  StatusId?: number;
  Status: IActivityStatusDto;
  OnlineComment: string;
  ActivitySubactivityId?: number;
  ActivitySubactivity: IActivitySubactivityDto;
  UnitId: number;
  Unit: any;

}
export interface IActivitySubactivityDocumentTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  ActivitySubactivityId: number;
  Name: string;
  ProfessionType: string;

}
export interface IActivityDto extends IValidatableDto, ICommentsDtoHost, IDomainChecklistDtoHost {
  ChildActivityId?: number;
  Staff: string;
  OnlineComment: string;
  StartDate?: Date;
  EndDate?: Date;
  ExpectedDate?: Date;
  CanceledDate?: Date;
  StatusModifiedDate?: Date;
  StatusModifiedBy: string;
  StatusId?: number;
  Status: IActivityStatusDto;
  ActivitySubactivityId?: number;
  ActivitySubactivity: IActivitySubactivityDto;
  UnitId: number;
  Unit: any;
  PreviousActivities: IActivityPreviousActivityDto[];
  Outcomes: IActivityOutcomeDto[];
  Participants: IActivityParticipantDto[];
  RelatedTo: IActivityRelatedToDto[];
  ActivityContractConsultants: any[];

}
export interface IActivityOutcomeDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  ActivityId: number;
  OutcomeTypeId?: number;
  OutcomeDate?: Date;
  OutcomeDescription: string;
  Activity: IActivityDto;
  OutcomeType: IActivityOutcomeTypeDto;

}
export interface IActivityOutcomeTypeDto extends ILookupDto, IAuditModifications, IAuditCreate {

}
export interface IActivityOutcomeTypeMaintenanceDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IActivityOutcomeTypeMaintenanceListDto extends IValidatableListDto<IActivityOutcomeTypeMaintenanceDto, IActivityOutcomeTypeMaintenanceListSearchCriteriaDto> {

}
export interface IActivityOutcomeTypeMaintenanceListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  Description: string;

}
export interface IActivityParticipantDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  ActivityId?: number;
  ParticipantEntityId?: number;
  ParticipantRoleId?: number;
  Recommendation: string;
  RecommendationDate?: Date;
  IsRecusedIndicator: boolean;
  IsNonParticipantIndicator: boolean;
  IsSignedOff: boolean;
  SignedOffDate?: Date;
  ParticipantDisplayName: string;
  GroupId?: number;
  ParticipantRole: IActivityParticipantRoleDto;
  Group: IEntityGroupDto;

}
export interface IActivityParticipantRoleDto extends ILookupDto, IAuditModifications, IAuditCreate {
  GroupTypeId?: number;

}
export interface IActivityParticipantRoleMaintenanceDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {
  GroupTypeId?: number;
  EntityGroupType: IEntityGroupTypeDto;

}
export interface IActivityParticipantRoleMaintenanceListDto extends IValidatableListDto<IActivityParticipantRoleMaintenanceDto, IActivityParticipantRoleMaintenanceListSearchCriteriaDto> {

}
export interface IActivityParticipantRoleMaintenanceListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  Description: string;
  GroupTypeId?: number;

}
export interface IActivityPreviousActivityDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  ActivityId?: number;
  PreviousActivityId?: number;
  PreviousActivity: IActivityInfoDto;

}
export interface IActivityRelatedToDto extends IValidatableDto, IAuditCreate, IAuditModifications, IEntityRelatedToItemSupport {
  ActivityId: number;
  IsPrimary: boolean;
  ParentDomainId?: number;
  ParentDomainKeyId?: number;
  TemporaryParentDomainKeyId?: any;
  Activity: IActivityDto;

}
export interface IActivitySearchCriteriaDto extends ISearchCriteriaDto, IFunctionalItemSupport {
  ActivityId?: number;
  EntityId?: number;
  LastName: string;
  FirstName: string;
  MiddleName: string;
  IncludeSubject: boolean;
  IncludeParticipants: boolean;
  ConsultantId?: number;
  ContractId?: number;
  IncludeContractConsultants: boolean;
  IncludeContractHolders: boolean;
  StaffAssignedInitials: string;
  UnitId?: number;
  ActivityTypeId?: number;
  ActivitySubTypeId?: number;
  StartDateFrom?: Date;
  StartDateTo?: Date;
  ExpectedDateFrom?: Date;
  ExpectedDateTo?: Date;
  EndDateFrom?: Date;
  EndDateTo?: Date;
  StatusId?: number;
  StatusModifiedDateFrom?: Date;
  StatusModifiedDateTo?: Date;
  HasSelection: boolean;

}
export interface IActivitySearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  EntityId: number;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  UnitName: string;
  ActivityTypeDescription: string;
  SubActivityTypeDescription: string;
  RelatedToDescription: string;
  OutcomeDescription: string;
  Staff: string;
  ChecklistItemCount: number;
  OpenChecklistItemCount: number;
  IsChecklistApplicable: boolean;
  IsChecklistComplete: boolean;
  StatusId: number;
  StatusDescription: string;
  StatusModifiedBy: string;
  StatusModifiedDate?: Date;
  StartDate?: Date;
  EndDate?: Date;
  ExpectedDate?: Date;

}
export interface IActivityStatusAspectStatusMaintenanceDto extends IValidatableDto, IAuditCreate, IAuditModifications, IAspectStatusHost {
  StatusId: number;
  AspectStatusId: number;
  Status: IActivityStatusDto;

}
export interface IActivityStatusDto extends ILookupDto {
  AspectStatuses: any[];

}
export interface IActivityStatusHistorySearchCriteriaDto extends ISearchCriteriaDto {
  ActivityId?: number;

}
export interface IActivityStatusHistorySearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  ActivityId: number;
  StatusId: number;
  StatusModifiedDate: Date;
  StatusModifiedBy: string;
  StatusDescription: string;
  Activity: IActivityDto;
  Status: IActivityStatusDto;

}
export interface IActivitySubactivityDto extends ILookupDto, IAuditCreate, IAuditModifications {
  ActivityTypeId: number;
  ActivitySubtypeId: number;
  IsDisplayedOnClosingLetters: boolean;
  ClosingLetterDisplayPhrase: string;
  ClosingLetterDisplayHierarchy?: number;
  ViewCorrespondenceRight: string;
  CreateCorrespondenceRight: string;
  UpdateCorrespondenceRight: string;
  DeleteCorrespondenceRight: string;
  ViewActivityRight: string;
  CreateActivityRight: string;
  UpdateActivityRight: string;
  DeleteActivityRight: string;
  ActivitySubtype: IActivitySubtypeDto;
  ActivityType: IActivityTypeDto;

}
export interface IActivitySubactivityLetterDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  ActivitySubactivityId: number;
  LetterId: number;
  ActivitySubactivity: IActivitySubactivityDto;

}
export interface IActivitySubactivityMaintenanceDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  ActivityTypeId?: number;
  ActivitySubtypeId?: number;
  DisplayOnClosingLetters: boolean;
  ClosingLetterDisplayPhrase: string;
  ClosingLetterDisplayHierarchy?: number;
  ViewCorrespondenceRight: string;
  CreateCorrespondenceRight: string;
  UpdateCorrespondenceRight: string;
  DeleteCorrespondenceRight: string;
  ViewActivityRight: string;
  CreateActivityRight: string;
  UpdateActivityRight: string;
  DeleteActivityRight: string;
  ActivityType: IActivityTypeDto;
  ActivitySubtype: IActivitySubtypeDto;
  DocumentTypes: any[];

}
export interface IActivitySubtypeDto extends ILookupDto {
  IsActivityScreen?: boolean;
  InitiatedDateLabel: string;
  ExpectedDateLabel: string;
  CompletedDateLabel: string;
  IsActivityScreenContractConsultantSection: boolean;
  RequiredBoardMemberSignOffCount: number;
  RequiredAttorneySignOffCount: number;
  CanceledDateLabel: string;
  ShowCanceledDate: boolean;
  InitiatedDateShowTime: boolean;
  ExpectedDateShowTime: boolean;
  CompletedDateShowTime: boolean;
  CanceledDateShowTime: boolean;
  DefaultOnlineComment: string;
  IsOnline?: boolean;
  IsChecklistVisible: boolean;
  AllowDocumentDisplayNameInput: boolean;
  DefaultDaysUntilDue?: number;

}
export interface IActivitySubtypeMaintenanceDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {
  IsActivityScreen?: boolean;
  InitiatedDateLabel: string;
  ExpectedDateLabel: string;
  CompletedDateLabel: string;
  IsActivityScreenContractConsultantSection?: boolean;
  RequiredBoardMemberSignOffCount?: number;
  RequiredAttorneySignOffCount?: number;
  CanceledDateLabel: string;
  ShowCanceledDate?: boolean;
  InitiatedDateShowTime?: boolean;
  ExpectedDateShowTime?: boolean;
  CompletedDateShowTime?: boolean;
  CanceledDateShowTime?: boolean;
  DefaultOnlineComment: string;
  IsOnline?: boolean;
  IsChecklistVisible: boolean;
  AllowDocumentDisplayNameInput: boolean;
  DefaultDaysUntilDue?: number;
  ActivitySubtypeOutcomes: IActivitySubtypeOutcomeMaintenanceDto[];

}
export interface IActivitySubtypeMaintenanceListDto extends IValidatableListDto<IActivitySubtypeMaintenanceDto, IActivitySubtypeMaintenanceListSearchCriteriaDto> {

}
export interface IActivitySubtypeMaintenanceListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  Description: string;

}
export interface IActivitySubtypeOutcomeMaintenanceDto extends IValidatableDto {
  ActivitySubtypeId?: number;
  ActivityOutcomeTypeId?: number;
  ActivityOutcomeType: IActivityOutcomeTypeDto;
  ActivitySubtype: IActivitySubtypeDto;

}
export interface IActivityTypeDto extends ILookupDto {
  ParentUnits: any[];

}
export interface IActivityTypeMaintenanceDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {
  ActivitySubactivities: IActivitySubactivityMaintenanceDto[];

}
export interface IActivityTypeMaintenanceListDto extends IValidatableListDto<IActivityTypeMaintenanceDto, IActivityTypeMaintenanceListSearchCriteriaDto> {

}
export interface IActivityTypeMaintenanceListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  ActivityTypeId: number;
  Description: string;

}
export interface IAddressInfoDto extends IDto, IAuditCreate, IAuditModifications {
  Line1: string;
  Line2: string;
  Line3: string;
  City: string;
  State: string;
  Zip: string;
  County: string;
  Country: string;
  IsPublic: boolean;
  IsMailing: boolean;
  AddressBlock: string;

}
export interface IAddressLocationTypeDto extends ILookupDto {

}
export interface IAddressTypeDto extends IBaseDto {
  Description: string;
  IsInactive: boolean;
  Hierarchy: number;
  FullDescription: string;
  FunctionTypeId?: number;
  IsPublic?: boolean;
  ParentAddressTypeId?: number;
  Parent: IAddressTypeDto;

}
export interface IAddressTypeMaintenanceDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  Description: string;
  IsInactive: boolean;
  Hierarchy: number;
  FunctionTypeId?: number;
  IsPublic?: boolean;
  ParentId?: number;
  EntityAddresses: IEntityAddressDto[];
  LookupAddressTypes: IAddressTypeDto[];
  Parent: IAddressTypeDto;
  FunctionType: IFunctionTypeDto;

}
export interface IAddressTypeMaintenanceListDto extends IValidatableListDto<IAddressTypeMaintenanceDto, IAddressTypeMaintenanceListSearchCriteriaDto> {

}
export interface IAddressTypeMaintenanceListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  Description: string;

}
export interface IAdHocDataRequestRequestDto extends IDataRequestRequestDto {
  DataRequestSourceId: number;
  SelectedCriteriaDescription: string;
  SearchCriteria: any;

}
export interface IAdHocStatisticalDataRequestRequestDto extends IAdHocDataRequestRequestDto {
  SelectedGroupByCriteriaDescription: string;
  GroupByCriteria: any;

}
export interface IAnswerDto extends ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IAnswerMaintenanceDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IAnswerMaintenanceListDto extends IValidatableListDto<IAnswerMaintenanceDto, IAnswerMaintenanceListSearchCriteriaDto> {

}
export interface IAnswerMaintenanceListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  Description: string;

}
export interface IAspectDto extends ILookupDto {

}
export interface IAspectMaintenanceDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IAspectMaintenanceListDto extends IValidatableListDto<IAspectMaintenanceDto, IAspectMaintenanceListSearchCriteriaDto> {

}
export interface IAspectMaintenanceListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  Description: string;

}
export interface IAspectStatusHost {
  AspectStatus: IAspectStatusDto;

}
export interface IAspectStatusDto extends ILookupDto {
  AspectId: number;
  Aspect: IAspectDto;

}
export interface IAspectStatusMaintenanceDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {
  AspectId?: number;
  Aspect: IAspectDto;

}
export interface IAspectStatusMaintenanceListDto extends IValidatableListDto<IAspectStatusMaintenanceDto, IAspectStatusMaintenanceListSearchCriteriaDto> {

}
export interface IAspectStatusMaintenanceListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  Description: string;
  AspectId?: number;

}
export interface IAuditCreate {
  CreatedBy: string;
  CreatedDate: Date;

}
export interface IAuditModifications {
  ModifiedDate: Date;
  ModifiedBy: string;

}
export interface IBagDto extends IDto {
  Key: string;
  BagItems: IBagItemDto[];

}
export interface IBagDtoHost {
  Bag: IBagDto;

}
export interface IBagItemDto extends IDto {
  Key: string;
  BagItemType: number;
  Values: string[];

}
export interface IBaseDto extends IDto {
  Id: number;
  IsNew: boolean;
  IsDeleted: boolean;
  LocalId: string;



  hasLocalId(): boolean;

  resetLocalId(): void;

}
export interface ICanAddItemToCartRequestDto extends IDto {
  CartId: number;
  ServiceId: number;

}
export interface ICanAddItemToCartResponseDto extends IDto {
  Result: boolean;

}
export interface ICanCancelWebServiceRequestDto extends IDto {
  WebServiceHistoryId: number;

}
export interface ICanCancelWebServiceResponseDto extends IDto {
  Result: boolean;
  TransactionExistsConfirmationDoesNotExist: boolean;

}
export interface ICanRemoveItemFromCartRequestDto extends IDto {
  CartId: number;
  CartItemId: number;

}
export interface ICanRemoveItemFromCartResponseDto extends IDto {
  Result: boolean;
  TransactionExistsConfirmationDoesNotExist: boolean;
  CartId?: number;
  CartItemId?: number;
  WebServiceHistoryId?: number;

}
export interface ICanSubmitCartRequestDto extends IDto {
  CartId: number;

}
export interface ICanSubmitCartResponseDto extends IDto {
  CartId: number;
  Result: boolean;
  TransactionExistsConfirmationDoesNotExist: boolean;
  TransactionExistsUnsuccessfulConfirmationExists: boolean;
  WebTransactionCreationStrategy: string;
  MajorKey: string;
  MinorKey: string;

}
export interface ICaptchaCredentialDto extends ICredentialDto {
  Response: string;

}
export interface ICashCreditCardDto extends IBaseDto, IAuditModifications, IAuditCreate {
  CashMasterId: number;
  CreditCardTypeCode: string;
  ExpMonth: string;
  ExpYear: string;
  HolderName: string;
  AuthNbr: string;
  CreditCardType: ICreditCardTypeDto;

}
export interface ICashDetailDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  CashMasterId: number;
  SourceCode: string;
  Amount: number;
  FeeComment: string;
  IsCashApplied: boolean;
  SourceCodeItem: ISourceCodeDto;
  ProcessFee: IProcessFeeDto;

}
export interface ICashMasterDto extends IValidatableDto, IParentDomainItemSupport, IDomainItemSupport, IAuditModifications, IAuditCreate {
  DepositNumber: string;
  LineNumber?: number;
  LastName: string;
  MiddleName: string;
  FirstName: string;
  LicAppTypeCode: string;
  PaymentTypeCode: string;
  Amount: number;
  RevenueNumber: string;
  EntityId?: number;
  Payor: string;
  Notes: string;
  ProcessId?: number;
  RelatedCashMasterId?: number;
  TotalFees: number;
  TotalProcessRequiredFees: number;
  AutoApplyNewFees: boolean;
  CashCreditCard: ICashCreditCardDto;
  PaymentType: IPaymentTypeDto;
  RelatedCashMaster: ICashMasterDto;
  CashDetailEntries: ICashDetailDto[];
  Process: IProcessDto;
  RelatedDomainSearchResultItem: ISearchResultItemDto;

}
export interface IChallengeInfoDto extends IValidatableDto, IBagDtoHost {
  ChallengeTypeId: number;
  EntityId: number;
  UserAccountId: number;
  Answer1: string;
  Answer2: string;
  UserName: string;
  SecurityQuestion1: string;
  SecurityQuestion2: string;
  UserPassword: string;
  ConfirmUserPassword: string;
  EmailAddress: string;
  PasswordVerificationToken: string;

}
export interface IChecklistGroupDto extends ILookupDto {
  Name: string;

}
export interface ICityDto extends ILookupDto {
  CountyId: number;
  CountyName: string;

}
export interface ICommentsDtoHost extends IFunctionalItemSupport, IEntityId {
  CommentSourceTypeId?: number;
  IncludeAllEntityComments: boolean;
  Comments: IEntityCommentDto[];

}
export interface ICommentSourceTypeDto extends ILookupDto {
  FunctionTypeId?: number;
  ShowWithEntityInd: boolean;
  FunctionType: IFunctionTypeDto;

}
export interface ICountryDto extends ILookupDto {
  Abbreviation: string;
  AlphaTwo: string;

}
export interface ICountyDto extends ILookupDto {

}
export interface ICredentialDto extends IPolymorphicDto {
  CredentialType: number;

}
export interface ICreditCardTypeDto extends ILookupDto {
  CreditCard: string;

}
export interface IDataRequestRequestDto extends IPolymorphicDto {
  DataRequestRequestType: number;
  EntityId: number;
  DocumentName: string;

}
export interface IDataRequestResponseDto extends IDto {
  CorrespondenceId?: number;
  ItemCount?: number;
  DataRequestFee?: number;
  RequestSucceeded: boolean;
  FeedbackMessage: string;
  ElapsedMilliseconds?: number;
  Request: IDataRequestRequestDto;

}
export interface IDataRequestSourceDto extends ILookupDto {
  DynamicContentMinorKey: string;
  IsAggregate: boolean;

}
export interface IDocumentDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  RowGuid: any;
  DisplayName: string;
  Description: string;
  OriginalFileName: string;
  FileExtension: string;
  DocumentBytesOmitted: boolean;
  ArchiveId?: number;
  ArchiveItemIdentifier: string;
  ArchiveDate?: Date;
  ArchiveLastIntegrityCheckDate?: Date;
  DoNotArchiveInd?: boolean;
  DocumentBytes: number[];
  RawFile: File;


}
export interface IDocumentHistoryItem extends IDto {
  DocumentId: number;
  DocumentDisplayName: string;
  FileExtension: string;
  CreatedDate: Date;
  ActivityTypeId: number;
  ActivityTypeDescription: number;
  ActivitySubtypeId: number;
  ActivitySubtypeDescription: number;
}
export interface IDocumentExtensionDto extends ILookupDto {
  Extension: string;
  MediaType: string;
  AllowOnlineUpload: boolean;

}
export interface IDocumentInfoDto extends IBaseDto, IAuditCreate, IAuditModifications {
  DisplayName: string;
  OriginalFileName: string;
  FileExtension: string;
  CorrespondenceDate?: Date;

}
export interface IDocumentRelatedToKeysDto extends IValidatableDto, IAuditCreate, IAuditModifications, IFunctionalItemKeysDto {

}
export interface IDocumentSearchCriteriaDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  LetterTypeId?: number;
  UnitId?: number;
  ActivityTypeId?: number;
  ActivitySubtypeId?: number;
  RelatedTo: IDocumentRelatedToKeysDto[];

}
export interface IDomainAuditDto extends IValidatableDto, IDomainChecklistDtoHost, IParentDomainItemSupport, IAuditCreate, IAuditModifications, IFunctionalItemSupport, IParentFunctionalItemSupport {
  ItemReferenceValue: string;
  EntityId?: number;
  StatusId?: number;
  StatusModifiedDate?: Date;
  StatusModifiedBy: string;
  TypeId?: number;
  DueDate?: Date;
  ParentDomainDescription: string;
  IsExempt: boolean;
  Status: IDomainAuditStatusDto;
  Type: IDomainAuditTypeDto;

}
export interface IDomainAuditDtoHost extends IDomainItemSupport {
  DomainAudits: IDomainAuditDto[];

}
export interface IDomainAuditStatusDto extends ILookupDto {
  PostOnlineRecordsSubmissionStatusId?: number;
  IsOnlineRecordsSubmissionAllowed?: boolean;

}
export interface IDomainAuditTypeDto extends ILookupDto {
  AllowOnlineRecordsSubmission: boolean;
  FileUploadActivityTypeId?: number;
  FileUploadActivitySubtypeId?: number;

}
export interface IDomainChecklistDtoHost extends IPolymorphicDto, IDomainItemSupport {
  DomainChecklist: IDomainChecklistItemDto[];
  HostStaffAssigned: string;

}
export interface IDomainChecklistItemDto extends IValidatableDto, IPolymorphicDto, IParentDomainItemSupport, IChildDomainItemSupport, IDomainItemSupport, IAuditCreate, IAuditModifications, IFileUploadDtoHost {
  ChecklistItemTemplateId: number;
  StatusId?: number;
  Comment: string;
  OnlineComment: string;
  GroupName: string;
  GroupDisplayOrder: number;
  Description: string;
  DerivedDescription: string;
  Summary: string;
  DueDate?: Date;
  SentDate?: Date;
  ReceivedDate?: Date;
  StatusDate?: Date;
  StaffAssigned: string;
  UploadCorrespondenceId?: number;
  IsComplete: boolean;
  ChecklistItemTemplate: ILookupDomainChecklistItemDto;
  Status: IDomainChecklistItemStatusDto;

}

export interface IDomainQuestionnaireChecklistItemDto extends IDomainChecklistItemDto, IDomainQuestionnaireDtoHost {

}

export interface IDomainChecklistItemStatusAspectStatusDto extends IBaseDto {
  LookupDomainChecklistItemStatusId: number;
  AspectStatusId: number;
  AspectStatus: IAspectStatusDto;

}
export interface IDomainChecklistItemStatusDto extends ILookupDto {
  StatusGroupId?: number;
  LookupDomainChecklistItemStatusGroup: IDomainChecklistItemStatusGroupDto;
  AspectStatuses: IDomainChecklistItemStatusAspectStatusDto[];

}
export interface IDomainChecklistItemStatusGroupDto extends ILookupDto {

}
export interface IDomainCriminalBackgroundCheckDto extends IValidatableDto, IDomainChecklistDtoHost, IParentDomainItemSupport, IAuditCreate, IAuditModifications {
  ItemReferenceValue: string;
  EntityId?: number;
  StatusId?: number;
  StatusModifiedDate?: Date;
  StatusModifiedBy: string;
  ParentDomainDescription: string;
  Status: IDomainCriminalBackgroundCheckStatusDto;

}
export interface IDomainCriminalBackgroundCheckDtoHost extends IDomainItemSupport {
  IsCriminalBackgroundCheckRequired?: boolean;
  ReferenceValue: string;
  CriminalBackgroundChecks: IDomainCriminalBackgroundCheckDto[];

}
export interface IDomainCriminalBackgroundCheckStatusAspectStatusDto extends IBaseDto {
  DomainCriminalBackgroundCheckStatusId: number;
  AspectStatusId: number;
  AspectStatus: IAspectStatusDto;

}
export interface IDomainCriminalBackgroundCheckStatusDto extends ILookupDto {
  AspectStatuses: IDomainCriminalBackgroundCheckStatusAspectStatusDto[];

}
export interface IDomainFormAnswerDto extends ILookupDto, IAuditCreate, IAuditModifications {
  IsAnswer: boolean;
  SectionQuestionId: number;
  IsExplanationRequired: boolean;

}
export interface IDomainFormDto extends IValidatableDto, IParentDomainItemSupport, IAuditCreate, IAuditModifications, IDomainItemSupport, IConcurrencySupport {
  FormId?: number;
  Form: ILookupDomainFormDto;
  StatusId?: number;
  Status: IDomainFormStatusDto;
  StatusModifiedBy: string;
  StatusModifiedDate: Date;
  IsReadyToScore: boolean;
  QuestionAnswers: IDomainFormQuestionAnswerDto[];

}
export interface IDomainFormDtoHost extends IDomainItemSupport {
  HostHasIncompleteDomainForm?: boolean;
  DomainForms: IDomainFormDto[];

}
export interface IDomainFormQuestionAnswerDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  FormId: number;
  SectionQuestionId: number;
  AnswerId?: number;
  AnswerText: string;
  Explanation: string;
  Question: IDomainFormSectionQuestionDto;
  Answer: IDomainFormAnswerDto;

}
export interface IDomainFormQuestionTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IDomainFormSectionDto extends ILookupDto, IAuditCreate, IAuditModifications {
  SectionQuestions: IDomainFormSectionQuestionDto[];

}
export interface IDomainFormSectionQuestionDto extends ILookupDto, IAuditCreate, IAuditModifications {
  QuestionTypeId: number;
  Rational: string;
  QuestionType: IDomainFormQuestionTypeDto;
  PossibleAnswers: IDomainFormAnswerDto[];

}
export interface IDomainFormStatusDto extends ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IDomainIdentifierDto extends IValidatableDto, IParentDomainItemSupport, IDomainItemSupport, IAuditCreate, IAuditModifications {
  Value: string;
  DomainIdentifierTypeId: number;
  DomainIdentifierType: IDomainIdentifierTypeDto;
}
export interface IDomainIdentifierDtoHost extends IPolymorphicDto, IDomainItemSupport {
  DomainIdentifiers: IDomainIdentifierDto[];

}
export interface IDomainIdentifierTypeDto extends ILookupDto {
  DomainId: number;
  FormatRegex: string;
  FormatMessage: string;
  ViewRight: string;
  CreateRight: string;
  UpdateRight: string;
  DeleteRight: string;
  DisplayOnline: boolean;
  EditableOnline: boolean;
  IsCredentialVerification: boolean;
}
export interface IDomainInspectionDtoHost extends IDomainItemSupport {
  DomainInspections: any[];

}
export interface IDomainItemDto extends ILookupDto {
  ProfessionTypeId: number;
  HasInterface?: boolean;
  InterfaceName: string;
  IsChecklistVisible: boolean;

}
export interface IDomainKeywordDtoHost extends IDomainItemSupport {
  DomainKeywords: IDomainKeywordDto[];
  KeywordProfessionTypeId?: number;

}
export interface IDomainKeywordGroupDto extends ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IDomainQuestionnaireAnswerDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  QuestionId: number;
  DomainQuestionnaireId: number;
  AnswerId: number;
  ReviewRequiredChecklistId?: number;
  Response: string;
  ResponseTitle: string;
  IsResponseRequired?: boolean;
  IsReviewRequired?: boolean;
  Question: IDomainQuestionnaireQuestionDto;
  DomainQuestionnaire: IDomainQuestionnaireDto;
  Answer: IAnswerDto;

}
export interface IDomainQuestionnaireDto extends IValidatableDto, IParentDomainItemSupport, IDomainItemSupport, IAuditCreate, IAuditModifications {
  LookupDomainQuestionnaireId: number;
  LookupDomainQuestionnaire: ILookupDomainQuestionnaireDto;
  Answers: IDomainQuestionnaireAnswerDto[];

}
export interface IDomainQuestionnaireDtoHost extends IDomainItemSupport {
  StrongestQuestionnaire: IDomainQuestionnaireDto;
  DomainQuestionnaires: IDomainQuestionnaireDto[];

}
export interface IDomainQuestionnaireQuestionDto extends IValidatableDto {
  QuestionnaireId: number;
  QuestionNumber: string;
  Question: string;
  IsLegalQuestion: boolean;
  SequenceNumber: number;
  ReviewRequiredAnswerId?: number;
  DefaultAnswerId: number;
  IsUnansweredAllowed?: boolean;
  IsYesAllowed?: boolean;
  IsNoAllowed?: boolean;
  IsAnsweredLastYearAllowed?: boolean;
  IsResponseRequired: boolean;
  ResponseTitle: string;
  Questionnaire: ILookupDomainQuestionnaireDto;
  ReviewRequiredAnswer: IAnswerDto;
  DefaultAnswer: IAnswerDto;
  Answers: IDomainQuestionnaireAnswerDto[];

}
export interface IDomainReasonForChangeDtoHost extends IDomainItemSupport {
  ReasonForChangePropertyNames: string[];
  DomainReasonForChangeItems: any[];

}
export interface IDto {

}
export interface IDynamicContentCategoryDto extends ILookupDto {

}
export interface IDynamicContentDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {
  PresentationTypeId?: number;
  CategoryId?: number;
  LocationTypeId?: number;
  MajorKey: string;
  MinorKey: string;
  Content: string;
  GroupName: string;
  PresentationType: IDynamicContentPresentationTypeDto;
  Category: IDynamicContentCategoryDto;
  LocationType: IDynamicContentLocationTypeDto;

}
export interface IDynamicContentLocationTypeDto extends ILookupDto {

}
export interface IDynamicContentPresentationTypeDto extends ILookupDto {

}
export interface IEntityAddressDto extends IValidatableDto, IFunctionalItemSupport, IAuditCreate, IAuditModifications {
  EntityId: number;
  IsCurrent: boolean;
  IsPublic: boolean;
  IsMailing: boolean;
  Line1: string;
  Line2: string;
  Line3: string;
  City: string;
  State: string;
  Zip: string;
  CountyId?: number;
  Country: string;
  County: ICountyDto;
  AddressType: IAddressTypeDto;
  AddressLocationType: IAddressLocationTypeDto;
  AddressFull: string;
  StreetLines: string;

}
export interface IEntityAddressDtoHost extends IFunctionalItemSupport, IDomainItemSupport, IEntityId {
  AddressTypeId: number;
  Addresses: IEntityAddressDto[];
  AddressMailing: IEntityAddressDto;
  AddressPublic: IEntityAddressDto;

}
export interface IEntityAddressSearchResultItemDto extends ISearchResultItemDto, IAuditCreate, IAuditModifications {
  Id: number;
  EntityId: number;
  FunctionNumber: string;
  IsCurrent: boolean;
  IsPublic: boolean;
  IsMailing: boolean;
  Line1: string;
  Line2: string;
  Line3: string;
  City: string;
  State: string;
  Zip: string;
  County: string;
  Country: string;
  AddressType: string;
  AddressTypeId: number;
  AddressLocationType: string;
  AddressFull: string;

}
export interface IEntityAssociateDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId: number;
  AssociateEntityId: number;
  AssociateTypeId: number;
  Comment: string;
  BeginDate?: Date;
  EndDate?: Date;
  AssociateType: IEntityAssociateTypeDto;
  EntityInfo: IEntityInfoDto;
  AssociatedEntityInfo: IEntityInfoDto;

}
export interface IEntityAssociateTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  CardinalityTypeId: number;
  EntityGroupId: number;

}
export interface IEntityCommentDto extends IValidatableDto, IFunctionalItemSupport, IAuditCreate, IAuditModifications {
  Comment: string;
  IsConfidential?: boolean;
  IsAlert?: boolean;
  SourceTypeId?: number;
  FullFunctionNumber: string;
  EntityId: number;
  ProfessionType: IProfessionTypeDto;
  SourceType: ICommentSourceTypeDto;

}
export interface IEntityDto extends IValidatableDto, IExtendedProperties, ICommentsDtoHost, IDomainIdentifierDtoHost, IEntityAddressDtoHost, IConcurrencySupport, IAuditCreate, IAuditModifications, IDomainReasonForChangeDtoHost {
  IsIndividual: boolean;
  EthnicGroupId?: number;
  UsePrimaryNameAsProfessionalName: boolean;
  NclexCandidateId: string;
  LastName: string;
  MiddleName: string;
  FirstName: string;
  SecondaryLastName: string;
  SecondaryMiddleName: string;
  SecondaryFirstName: string;
  SecondaryTitle: string;
  SecondarySuffix: string;
  AlternateName: string;
  BirthDate?: Date;
  BirthCity: string;
  BirthState: string;
  BirthCounty: string;
  BirthCountry: string;
  DeathDate?: Date;
  DeathCity: string;
  DeathState: string;
  DeathCounty: string;
  DeathCountry: string;
  Title: string;
  Suffix: string;
  Ssn: string;
  Gender: string;
  Email: string;
  PrivateEmail: string;
  Website: string;
  DriversLicenseNbr: string;
  DriversLicenseState: string;
  IsMilitary: boolean;
  MilitaryResponseTypeId?: number;
  MilitaryDischargeDate?: Date;
  OrganizationTypeId?: number;
  OrganizationType: any;
  ProfessionalSuffix: string;
  LFMName: string;
  FLName: string;
  TLName: string;
  TFLName: string;
  SecondaryTLName: string;
  SecondaryFLName: string;
  SecondaryTFLName: string;
  FMLName: string;
  SecondaryFMLName: string;
  FMLSName: string;
  SecondaryFMLSName: string;
  FMLSDName: string;
  SecondaryFMLSDName: string;
  FMILSDName: string;
  SecondaryFMILSDName: string;
  FMILName: string;
  SecondaryFMILName: string;
  FMILSName: string;
  SecondaryFMILSName: string;
  PriorNames: string;
  PhoneNumbers: IEntityPhoneDto[];
  GroupMemberships: IEntityGroupMembershipDto[];
  Associates: IEntityAssociateDto[];
  AssociatedWith: IEntityAssociateDto[];
  NameHistory: IEntityNameHistoryDto[];
  EntityOwnerships: IEntityOwnershipDto[];

}
export interface IEntityGroupDto extends ILookupDto {
  EntityGroupTypeId: number;
  EntityGroupType: IEntityGroupTypeDto;

}
export interface IEntityGroupMemberDto extends IDto {
  Id: number;
  IsIndividual: boolean;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  LFMName: string;
  FullName: string;
  City: string;
  State: string;
  Country: string;
  Zip: string;

}
export interface IEntityGroupMemberSearchResultItemDto extends ISearchResultItemDto {
  Id: number;
  IsIndividual: boolean;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  LFMName: string;
  FullName: string;
  City: string;
  State: string;
  Country: string;
  Zip: string;

}
export interface IEntityGroupMemberSearchCriteriaDto extends ISearchCriteriaDto {
  GroupId?: number;
  SchoolName: string;
  City: string;
  State: string;
  Country: string;
  Zip: string;
  SearchCityInName?: boolean;

}
export interface IEntityGroupMembershipDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId: number;
  BeginDate?: Date;
  EndDate?: Date;
  EntityGroup: IEntityGroupDto;

}
export interface IEntityGroupTypeDto extends ILookupDto {
  IsSystemOnly: boolean;

}
export interface IEntityId {
  EntityId?: number;

}
export interface IEntityInfoDto extends IDto {
  Id: number;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  FullName: string;
  UsePrimaryNameAsProfessionalName: boolean;
  SecondaryFirstName: string;
  SecondaryMiddleName: string;
  SecondaryLastName: string;
  SecondaryTitle: string;
  SecondarySuffix: string;
  FullSecondaryName: string;
  BirthDate?: Date;
  DeathDate?: Date;
  Title: string;
  Suffix: string;
  Ssn: string;
  Gender: string;
  GenderDescription: string;
  Phone: string;
  PhoneNumbers: IEntityPhoneInfoDto[];
  PublicAddressInfo: IAddressInfoDto;
  Email: string;
  PrivateEmail: string;
  Website: string;
  IsIndividual: boolean;
  IsOnlineUser: boolean;
  SecurityCode: string;
  HasAlert: boolean;
  LegacyHoldCount?: number;
  WatchCount?: number;
  AlertWatchCount?: number;
  CommentsCount?: number;
  AlertCommentsCount?: number;
  ProfessionalSuffix: string;
  LFMName: string;
  FLName: string;
  TLName: string;
  TFLName: string;
  SecondaryTLName: string;
  SecondaryFLName: string;
  SecondaryTFLName: string;
  FMLName: string;
  SecondaryFMLName: string;
  FMLSName: string;
  SecondaryFMLSName: string;
  FMLSDName: string;
  SecondaryFMLSDName: string;
  FMILSDName: string;
  SecondaryFMILSDName: string;
  FMILName: string;
  SecondaryFMILName: string;
  FMILSName: string;
  SecondaryFMILSName: string;

}
export interface IEntityNameHistoryDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId: number;
  UsePrimaryNameAsProfessionalName: boolean;
  LastName: string;
  MiddleName: string;
  FirstName: string;
  SecondaryLastName: string;
  SecondaryFirstName: string;
  SecondaryMiddleName: string;
  SecondaryTitle: string;
  SecondarySuffix: string;
  Title: string;
  Suffix: string;
  AlternateName: string;
  FullName: string;

}
export interface IEntityPhoneDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId: number;
  PhoneTypeId: number;
  PhoneType: IPhoneTypeDto;
  Number: string;
  Extension: string;
  IsPublic: boolean;
  IsPrimary: boolean;
  IsVoicemail: boolean;

}
export interface IEntityPhoneInfoDto extends IBaseDto, IAuditCreate, IAuditModifications {
  EntityId: number;
  TypeId: number;
  Type: string;
  Number: string;
  Extension: string;
  IsPublic: boolean;
  IsPrimary: boolean;

}
export interface IErrorDto extends IDto {

}
export interface IEthnicGroupDto extends ILookupDto {

}
export interface IExceptionDto extends IDto {
  Id: number;
  InnerLevel: number;
  Type: string;
  Message: string;
  TargetSite: string;
  HelpLink: string;
  Source: string;
  StackTraceInfo: string;

}
export interface IExtendedProperties {
  ExtendedProperties: IExtendedPropertyDto[];

}
export interface IExtendedPropertyDto extends IDto {
  DisplayName: string;
  Value: string;
  FieldFormat: string;
  FieldName: string;
  Id: number;
  IsActive?: boolean;
  OrderById: number;
  IsRequiredField: boolean;

}
export interface IFileUploadDtoHost extends IBaseDto, IEntityId, IFunctionalItemSupport {
  Documents: IDocumentDto[];
  DocumentIds: number[];
  AdditionalRelatedToItems: IRelatedToBaseDto[];
  UnitId: number;
  ActivityTypeId: number;
  ActivitySubtypeId: number;
  ReviewStaffAssigned: string;
  ChecklistItemId?: number;

}
export interface IFileUploadResponseDto extends IValidatableDto {
  UploadedDocuments: IDocumentDto[];

}
export interface IFunctionalItemKeysDto extends IFunctionalItemSupport {
  Description: string;

}
export interface IFunctionTypeDto extends ILookupDto {
  Code: string;

}
export interface IGenderDto extends ILookupDto {
  Code: string;

}
export interface IIncidentDto extends IDto {
  Exceptions: IExceptionDto[];
  AppDomainName: string;
  MachineName: string;
  WindowsIdentity: string;
  FullName: string;
  UserComment: string;
  ReferenceGuid: any;

}
export interface IInformationFileDto extends IValidatableDto, ICommentsDtoHost, IDomainItemSupport, IAuditCreate, IAuditModifications {
  ReferenceValue: string;
  TypeId?: number;
  ReceivedDate?: Date;
  StatusId?: number;
  StatusDate?: Date;
  ReasonCodeId?: number;
  ResolutionId?: number;
  ResolutionDate?: Date;
  ReasonCode: any;
  Resolution: any;
  Status: any;
  Type: any;

}
export interface IInitializeChecklistItemResponseDto extends IDto {
  DomainChecklistItem: IDomainChecklistItemDto;
  DomainChecklistDtoHost: IDomainChecklistDtoHost;

}
export interface IInvoiceDto extends IValidatableDto, IAuditCreate, IAuditModifications, IEntityRelatedToItemSupport, ICommentsDtoHost {
  ProcessId?: number;
  StatusId?: number;
  EntityId?: number;
  Amount: number;
  Description: string;
  Fees: IInvoiceFeeDto[];
  Status: IInvoiceStatusDto;
  Process: IProcessDto;
  TotalFees: number;

}
export interface IInvoiceFeeDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  InvoiceId: number;
  SourceCode: string;
  SourceCodeItem: ISourceCodeDto;
  Amount: number;
  DisplayOrder: number;
  IsInactive: boolean;
  ProcessFee: IProcessFeeDto;

}
export interface IInvoiceInfoDto extends IDto {
  Id: number;
  Amount: number;
  Description: string;

}
export interface IInvoiceStatusDto extends ILookupDto {
  AspectStatuses: any[];

}
export interface ILookupDomainChecklistItemDto extends ILookupDto {
  DomainId?: number;
  DomainDisplayOrder?: number;
  DomainDescription: string;
  DomainChecklistItemSetId?: number;
  StatusGroupId?: number;
  GroupId: number;
  ChildDomainId?: number;
  DerivedDescriptionFormat: string;
  ViewRightId: string;
  CreateRightId: string;
  UpdateRightId: string;
  DeleteRightId: string;
  MinorKey: string;
  AllowMultiples: boolean;
  IsSystem: boolean;
  IsFollowUp: boolean;
  IsExcludedFromOnlineList: boolean;
  IsExcludedOnline: boolean;
  IsDueDateRequired: boolean;
  IsReceivedDateRequired: boolean;
  IsDueDateUnused: boolean;
  IsReceivedDateUnused: boolean;
  IsOnlineCommentUnused: boolean;
  IsStaffAssignedUnused: boolean;
  IsStaffAssignedRequired: boolean;
  StaffAssignedDefault: string;
  StaffAssignedRight: string;
  DefaultOnlineComment: string;
  AllowOnlineUpload: boolean;
  IsSentDateUnused: boolean;
  IsSentDateRequired: boolean;
  IsDueDateDefaultedFromSentDate: boolean;
  DefaultDaysUntilDue?: number;
  DefaultToHostAssigned: boolean;
  Group: IChecklistGroupDto;
  StatusGroup: IDomainChecklistItemStatusGroupDto;
  ChecklistDefault: any;
  ProfessionTypes: any[];
  NextChecklistItems: any[];

}
export interface ILookupDomainFormDto extends ILookupDto, IAuditCreate, IAuditModifications {
  DomainFormTypeId?: number;
  PassPercentage?: number;
  IsAutoScored: boolean;
  Sections: IDomainFormSectionDto[];

}
export interface ILookupDomainQuestionnaireDto extends IValidatableDto, ILookupDto, IAuditCreate, IAuditModifications {
  DomainId: number;
  ReviewRequiredChecklistItemId?: number;
  ValidFromDate?: Date;
  ValidToDate?: Date;
  InconsistentAnswerChecklistItemId?: number;
  AutoCompleteChecklistItemWhenNoReviewRequired?: boolean;
  DomainQuestionnaireTypeId?: number;
  ReviewRequiredChecklistItem: ILookupDomainChecklistItemDto;
  InconsistentAnswerChecklistItem: ILookupDomainChecklistItemDto;
  Questions: IDomainQuestionnaireQuestionDto[];

}
export interface ILookupDto extends IBaseDto {
  Description: string;
  IsInactive: boolean;
  DisplayOrder: number;

}
export interface IMilitaryResponseTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  MinorKey: string;
  IsMilitary?: boolean;
  AutoApplyEntityMilitary?: boolean;
  ApplicationChecklistItemId?: number;
  RenewalChecklistItemId?: number;
  DischargeDateApplicable?: boolean;

}
export interface INotificationDto extends IDto {
  Message: string;
  DomainId?: number;
  DomainKeyId?: number;
  MinorKey: string;

}
export interface INotificationDtoHost {
  Notifications: INotificationDto[];

}
export interface IOnlineAccountTypeDto extends ILookupDto {

}
export interface IOnlineActivityInfoDto extends IDto {
  Id: number;
  EntityId?: number;
  StartDate?: Date;
  EndDate?: Date;
  ExpectedDate?: Date;
  OnlineComment: string;
  StatusId?: number;
  Status: IActivityStatusDto;
  ActivitySubactivityId?: number;
  ActivitySubactivity: IActivitySubactivityDto;
  UnitId: number;
  Unit: any;
  RelatedTo: IActivityRelatedToDto[];

}
export interface IOnlineUserDto extends IValidatableDto, IEntityAddressDtoHost, IBagDtoHost, IDomainIdentifierDtoHost {
  UsePrimaryNameAsProfessionalName: boolean;
  UserAccountId?: number;
  EthnicGroupId?: number;
  SecondaryLastName: string;
  SecondaryMiddleName: string;
  SecondaryFirstName: string;
  SecondaryTitle: string;
  SecondarySuffix: string;
  LastName: string;
  FirstName: string;
  AlternateName: string;
  Ssn: string;
  MiddleName: string;
  Gender: string;
  Suffix: string;
  BirthCity: string;
  BirthState: string;
  BirthCountry: string;
  BirthCounty: string;
  Email: string;
  OrganizationEmail: string;
  PrivateEmail: string;
  Website: string;
  Title: string;
  IsMilitary: boolean;
  MilitaryResponseTypeId?: number;
  MilitaryDischargeDate?: Date;
  IsIndividual: boolean;
  DriversLicenseNbr: string;
  DriversLicenseState: string;
  BirthDate?: Date;
  UserAccount: IUserAccountDto;
  PhoneNumbers: IEntityPhoneDto[];
  Associates: IEntityAssociateDto[];
  AssociatedWith: IEntityAssociateDto[];
}
export interface IOnlineUserSearchCriteriaDto extends IDto {
  LicenseReferenceValue: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  BirthDate?: Date;
  Ssn: string;
  FirmNumber: string;
  CepNumber: string;
  EmailAddress: string;
  SecurityCode: string;
  IsIndividual: boolean;
  OnlineAccountTypeId: number;
  DomainIdentifierHost: IDomainIdentifierDtoHost;
  PasswordVerificationToken: string;
  ProcessTypeId?: number;

}
export interface IPayerAddressDto {
  PayerAddress1: string;
  PayerAddress2: string;
  PayerCity: string;
  PayerState: string;
  PayerZip: string;
  PayerCountry: string;

}
export interface IPaymentTypeDto extends ILookupDto {
  Type: string;
  IsExpense: boolean;

}
export interface IPhoneTypeDto extends ILookupDto {

}
export interface IPolymorphicDto extends IBaseDto {

}
export interface IPreBuiltDataRequestRequestDto extends IDataRequestRequestDto {
  MasterDataRequestCorrespondenceId?: number;

}
export interface IProcessDto extends ILookupDto, IAuditCreate, IAuditModifications {
  ProcessTypeId: number;
  LicenseTypeCode: string;
  Basis: string;
  StatusCode: string;
  StatusCodeTo: string;
  IsOnline?: boolean;
  WebServiceId?: number;
  UsBankProductCode: string;
  UsBankProductDescription: string;
  HasSupervisionPlan: boolean;
  FormTypeId?: number;
  SupervisorDesignationFormId?: number;
  FeesWaivedForActiveMilitary: boolean;
  IsCriminalBackgroundCheckRequired: boolean;
  CriminalBackgroundCheckApprovalRuleId?: number;
  CriminalBackgroundCheckDefaultStatusId?: number;
  OnlineComment: string;
  LicenseSubtypeId?: number;
  UsBankPaymentType: string;
  IsOpiate?: boolean;
  ProcessType: IProcessTypeDto;
  Fees: IProcessFeeDto[];

}
export interface IProcessFeeDto extends IBaseDto, IAuditCreate, IAuditModifications {
  ProcessId: number;
  SourceCode: string;
  Amount?: number;
  DisplayOrder?: number;
  SourceCodeItem: ISourceCodeDto;

}
export interface IProcessTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  DomainId: number;
  IsSystem: boolean;
  IsBlockedWhenHolds: boolean;

}
export interface IProfessionTypeDto extends ILookupDto {
  Code: string;
  PrerequisiteProfessionTypeId?: number;
  IsCityUsed: boolean;
  IsFacilityUsed: boolean;
  FacilityLabel: string;
  IsProgramUsed: boolean;
  ProgramLabel: string;
  IsPeriodDatesUsed: boolean;
  FacilityLicenseeUsed: boolean;
  DoingBusinessAsUsed: boolean;
  InternHoursWorkedUsed: boolean;
  IsLicenseSubtypeUsed: boolean;
  IsOpiateUsed: boolean;
  IsCsrsExemptionUsed: boolean;
  PrerequisiteProfessionType: IProfessionTypeDto;

}
export interface IProfileSummaryInfoDto extends IBaseDto {
  Type: number;
  MajorKey: string;
  MinorKey: string;
  Title: string;
  Description: string;
  Count: number;
  Parameters: string[];

}
export interface IRelatedToBaseDto extends IAuditCreate, IAuditModifications, IEntityRelatedToItemSupport, IValidatableDto {

}
export interface IRequestedDataReportInfoDto extends ISearchResultItemDto {
  CorrespondenceId: number;
  CorrespondenceDate: Date;
  DocumentName: string;
  CorrespondenceActivitySubtypeDescription: string;
  InfoRequestedDateReceived?: Date;
  InfoRequestedDescription: string;
  MasterCorrespondenceId?: number;
  MasterInfoRequestedDateReceived?: Date;
  DocumentId?: number;

}
export interface ISearchCriteriaDto extends IValidatableDto {
  MinimumFilterRequirementsMessage: string;
  TopResults?: number;
  IncludeAllResults: boolean;
  IncludeQuery: boolean;

}
export interface ISearchResultItemDto extends IDto {

}
export interface ISecurityQuestionDto extends ILookupDto, IAuditCreate, IAuditModifications {

}
export interface ISessionDto extends IDto {
  Token: string;
  UserId: string;
  UserName: string;
  EntityId: string;
  UserRights: IUserRightDto[];
  Initials: string;

}
export interface ISourceCodeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  SourceCode: string;
  Amount: number;
  IsManualApply: boolean;
  IsRenewal: boolean;
  IsInactiveRenewal: boolean;

}
export interface IStateDto extends IBaseDto {
  StateCode: string;
  Name: string;
  Country: string;
  IsInactive: boolean;
  DisplayOrder: number;

}
export interface IStepDataDto extends IDto {
  Action: string;
  Invoice: IInvoiceDto;
  Documents: IDocumentDto[];
  Errors: string[];
  Step: IWebServiceViewDto;
  Entity: IEntityDto;
  CriminalBackgroundCheckId?: number;
  Fees: IProcessFeeDto[];
  OnlineUserSearchCriteria: IOnlineUserSearchCriteriaDto;
  OnlineUserAccount: IUserAccountDto;
  DomainQuestionnaireDto: IDomainQuestionnaireDto;
  Bag: IBagDto;
  ChecklistItemsWithDocuments: IDomainChecklistItemDto[];
}
export interface ISuffixDto extends IDto {
  Id: number;
  IsInactive: boolean;
  DisplayOrder: number;
  Suffix: string;

}
export interface ISystemSettingDto extends IBaseDto, IAuditCreate, IAuditModifications {
  Key: string;
  Value: string;
  Description: string;

}
export interface ITimeCommitmentDto extends ILookupDto, IAuditCreate, IAuditModifications {

}
export interface ITitleDto extends ILookupDto {
  Title: string;

}
export interface IUnpaidDomainCriminalBackgroundInfoDto {
  UnpaidCriminalBackgroundCheckId?: number;

}
export interface IUserAccountDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId: number;
  UserName: string;
  UserPassword: string;
  ConfirmUserPassword: string;
  IsSupportPasswordSet: boolean;
  SupportPassword: string;
  SecurityQuestion1: string;
  SecurityAnswer1: string;
  SecurityQuestion2: string;
  SecurityAnswer2: string;
  PasswordResetVerificationTokenExpireDate?: Date;
  OnlineAccountTypeId?: number;
  Challenge: IChallengeInfoDto;
  OnlineAccountType: IOnlineAccountTypeDto;
  profile: IUserProfileInfoDto;
}
export interface IUserAccountListDto extends IValidatableListDto<IUserAccountDto, IUserAccountListSearchCriteriaDto> {

}
export interface IUserAccountListSearchCriteriaDto extends IValidatableListSearchCriteriaDto {
  EntityId?: number;

}
export interface IUserDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  UserId: string;
  FirstName: string;
  LastName: string;
  Initials: string;
  Phone: string;
  Title: string;
  Email: string;
  InternalEmail: string;
  UnitId?: number;
  EntityId?: number;
  Signature: number[];
  IsActive?: boolean;
  MiddleName: string;
  UserPreferences: any[];
  UserRights: IUserRightDto[];
  UserRoles: any[];
  FLName: string;
  FMLName: string;
  FMILName: string;

}
export interface IUserInfoDto extends IDto {
  Id: number;
  UserId: string;
  FirstName: string;
  LastName: string;
  Initials: string;
  Phone: string;
  Title: string;
  Email: string;
  InternalEmail: string;
  UnitId?: number;
  EntityId?: number;
  IsActive?: boolean;
  MiddleName: string;
  RightCodes: string[];
  FLName: string;
  FMLName: string;
  FMILName: string;

}
export interface IUsernamePasswordCredentialDto extends ICredentialDto {
  UserName: string;
  Password: string;

}
export interface IUserProfileInfoDto extends INotificationDtoHost {
  UserAccountTypeId: number;
  IsIndividual: boolean;
  IsDirectNameChangeAllowed: boolean;
  Cart: IWebCartDto;
  HasInformationFileHolds: boolean;
  ProfileSummaries: IProfileSummaryInfoDto[];
  DataRequests: IRequestedDataReportInfoDto[];
  InformationFileHolds: IInformationFileDto[];
  WebLinks: IWebLinkInfoDto[];
  Invoices: IInvoiceInfoDto[];
}
export interface IUserRightDto extends IDto {
  Code: string;
  Description: string;
  DirectRoles: string;

}
export interface IValidatableDto extends IDirty, IBaseDto {
  Validator: any;
  IsValid: boolean;
  IsObjectGraphValid: boolean;
  ValidationMode: string;
  Errors: ValidationFailureDto[];
  AdditionalErrors: ValidationFailureDto[];
  ChangeTracker: any;

}
export interface IValidatableListDto<TItem, TSearchCriteria> extends IValidatableDto {
  SearchCriteria: TSearchCriteria;
  Items: TItem[];

}
export interface IValidatableListSearchCriteriaDto extends ISearchCriteriaDto {
  IdentityCollection: number[];

}
export interface IWebCartDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  UserId: number;
  CartStatusId: number;
  ItemsTotal: number;
  FixedCartFeesTotal: number;
  VariableCartFeesTotal: number;
  CartFeesTotal: number;
  Total: number;
  Transactions: IWebTransactionDto[];
  Items: IWebCartItemDto[];
  WebCartFees: IWebCartFeeDto[];
  Status: IWebCartStatusDto;

}
export interface IWebCartFeeDto extends ILookupDto, IAuditCreate, IAuditModifications {
  SourceCode: string;
  Value?: number;
  IsPercent?: boolean;
  SourceCodeItem: ISourceCodeDto;

}
export interface IWebCartItemDto extends IBaseDto, IAuditCreate, IAuditModifications {
  CartId: number;
  ServiceHistoryId: number;
  Total: number;
  ServiceHistoryItem: IWebServiceHistoryDto;

}
export interface IWebCartStatusDto extends ILookupDto {

}
export interface IWebLinkInfoDto extends IDto {
  AlertMessage: string;
  DisplayName: string;
  DisplayOrder: number;
  DomainId: number;
  DomainKeyId: number;
  ProcessTypeId: number;
  ProcessId?: number;
  ServiceId?: number;
  ServiceMajorKey: string;
  RouteParameters: IWebLinkSegment[];
  ScopeKeys: number[];

}
export interface IWebLinkSegment {
  Key: string;
  Value: any;

}
export interface IWebServiceDto extends IBaseDto, IAuditCreate, IAuditModifications {
  Name: string;
  Title: string;
  Description: string;
  DisplayOrder: number;
  IsLoginRequired: boolean;
  IsHistoryTracked: boolean;
  IsCancellationAllowed: boolean;
  IsActive: boolean;
  TotalFees: number;
  ProcessId?: number;
  FeesWaivedForActiveMilitary: boolean;
  MajorKey: string;
  LinkCaption: string;
  ContinueLinkCaption: string;
  Steps: IWebServiceViewDto[];
  Fees: IProcessFeeDto[];

}
export interface IWebServiceHistoryDetailDto extends IBaseDto, IAuditCreate, IAuditModifications {
  ServiceHistoryId: number;
  StepOrder: number;
  ServiceViewId: number;
  StepDate: Date;
  StepData: IStepDataDto;
  StepContent: string;
  ErrorCount: number;
  StepErrors: string;
  StepTitle: string;
  StepAction: string;

}
export interface IWebServiceHistoryDto extends IValidatableDto, IFunctionalItemSupport, IAuditCreate, IAuditModifications {
  UserId?: number;
  LastStepIndex: number;
  ServiceId: number;
  Description: string;
  StartDate: Date;
  CompleteDate?: Date;
  ConfirmationDocumentId?: number;
  ProcessId?: number;
  HasFees: boolean;
  TotalFees: number;
  IsCanceled: boolean;
  WebCartItems: IWebCartItemDto[];
  Transactions: IWebTransactionDto[];
  StepDetails: IWebServiceHistoryDetailDto[];
  Process: IProcessDto;
  OmitFeeManagerCalculations: boolean;

}
export interface IWebServiceHistoryInfoDto extends IDto {
  ProcessTypeId: number;
  WebServiceHistoryId: number;
  ServiceInProgress: boolean;
  HasProcessFees: boolean;
  FunctionTypeId?: number;
  FunctionNumber?: number;
  IsCancellationAllowed: boolean;

}
export interface IWebServiceViewDto extends IDto, IAuditCreate, IAuditModifications {
  Id: number;
  StepOrder: number;
  ShortTitle: string;
  LongTitle: string;
  IsVisible: boolean;
  IsSaved: boolean;
  ServiceId?: number;
  IsActive: boolean;
  FilePath: string;
  ComponentName: string;
  TemplatePath: string;
  Icon: string;
  MinorKey: string;
  Parameters: string[];

}
export interface IWebTransactionConfirmationDto extends IPayerAddressDto, IBaseDto, IAuditCreate, IAuditModifications {
  TransactionId: number;
  ConfirmationNumber: string;
  BillerId: string;
  BillerProductCode: string;
  PaymentMethod: string;
  PaymentChannel: string;
  PaymentAmount: number;
  ConvenienceFee: number;
  PaymentEffectiveDate?: Date;
  TransactionMode: string;
  ConfirmationBy: string;
  ConfirmationDate: Date;
  PayerFirstName: string;
  PayerLastName: string;
  PayerFullName: string;
  PayerPhone: string;
  PayerEmail: string;
  PayerSsnFour: string;
  BillerBusinessdate?: Date;

}
export interface IWebTransactionDto extends IBaseDto, IAuditCreate, IAuditModifications {
  TransactionDate: Date;
  TransactionAmount: number;
  UserId: number;
  ProcessTypeId: number;
  WebCartId: number;
  HasProcessed: boolean;
  Confirmations: IWebTransactionConfirmationDto[];
  Events: IWebTransactionNotificationDto[];
  Fees: IWebTransactionFeeDto[];

}
export interface IWebTransactionFeeDto extends IDto, IAuditCreate, IAuditModifications {
  Id: number;
  SourceCode: string;
  Amount: number;
  TransactionId: number;
  ServiceHistoryId?: number;

}
export interface IWebTransactionNotificationDto extends IPayerAddressDto, IBaseDto, IAuditCreate, IAuditModifications {
  TransactionId: number;
  BillerId: string;
  BillerProductCode: string;
  PaymentMethod: string;
  PaymentChannel: string;
  EventDate: Date;
  Description: string;
  PayerFirstName: string;
  PayerLastName: string;
  PayerFullName: string;
  PayerPhone: string;
  PayerEmail: string;

}
export interface IWordDocumentBytesHost {
  HostDescription: string;
  OriginalFilePath: string;
  DocumentExtension: string;
  Document: number[];
  IsDocumentEdited?: boolean;
  HasDocument?: boolean;

}
export interface ValidationFailureDto {
  PropertyName: string;
  CustomState: any;
  ErrorMessage: string;

}
export interface IEntityOwnerTypeDto extends ILookupDto, IAuditCreate, IAuditModifications {

}
export interface IEntityOwnershipDto extends IValidatableDto, IAuditCreate, IAuditModifications {
  EntityId?: number;
  OwnerTypeId?: number;
  OwnerEntityId?: number;
  OwnerName: string;
  DerivedOwnerName: string;
  OwnerPercentage?: number;
  AcquiredDate?: Date;
  DisposedDate?: Date;
  Owner: IEntityInfoDto;
  EntityOwnerType: IEntityOwnerTypeDto;

}
export interface IDirty {
  IsDirty: boolean;
  IsObjectGraphDirty: boolean;

}

